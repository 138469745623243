<script>
import axios from 'axios'
import Vue from 'vue'
// const BASE_URL="http://192.168.1.50:8000"
// const BASE_URL="http://192.168.1.80:8888"//测试
var BASE_URL = ""//测试

// const BASE_URL="http://192.168.1.187:8080/"//准生产
// const BASE_URL="http://ccj.chexd.com/"//生产
window.WS=false
var WS_URL=''
var GBDATA={
        WS_STATUS:false,
        WS_MSG:''
    }
function ws_server(){
    window.WS = new WebSocket(WS_URL)
    window.WS.onopen = function () {
        GBDATA.WS_STATUS=true
        //console.log("socket已连接")
    }
    window.WS.onmessage = function (evt) {
        GBDATA.WS_MSG = JSON.parse(evt.data)
        //console.log("WS_MSG",GBDATA.WS_MSG)
        if(typeof(GBDATA.WS_MSG.data)!="undefined"){
            if(GBDATA.WS_MSG.data.type=="login"){
                window.localStorage.setItem('WSID',GBDATA.WS_MSG.data.id)
            }
        }
        
    }
    window.WS.onclose = function () {
        // 关闭 websocket
        GBDATA.WS_STATUS=false
        console.log("连接已关闭,5秒后尝试重连");
        setTimeout(function() { console.log('尝试连接');ws_server(); }, 5000); //延时重连
    }
}

axios.post("/index.php/index/wechat.wechat/ws", {})
.then((res) => {
    //console.log("ws", res.data)
    if (res.data.code == 0) {
        WS_URL = res.data.data
        ws_server()
    }
})
.catch(() => {

})
// 定义属性存储
var dateTime_value = new Date()
/*  父子组件事件触发搬运车 */
var globaleventBus = new Vue()
Vue.prototype.GLOBALBUS = globaleventBus

/* 定义每个属性的监听事件 */
const keys = Object.keys(GBDATA)
keys.forEach(objItem => {
  Object.defineProperty(GBDATA, objItem, {
    get: function() {
      return dateTime_value
    },
    set: function(newValue) {
        dateTime_value = newValue
        //console.log(`watch_${objItem}`,newValue)
        globaleventBus.$emit(`watch_${objItem}`, newValue)
    }
  })
})

export default {
    BASE_URL
}
</script>
