import 'babel-polyfill'
import Es6Promise from 'es6-promise'
require('es6-promise').polyfill()
Es6Promise.polyfill()

import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store'
// import ElementUI from 'element-ui';
Vue.config.silent = true//关闭[Vue warn] ，兼容IE内核
import 'normalize.css/normalize.css' // A modern alternative to CSS resets
// import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';

import 'amfe-flexible';//px转换rem
Vue.use(Vant);

import axios from 'axios'
window.eventBus = new Vue();  //注册bus
// axios.defaults.baseURL = "http://192.168.1.50:8000"

Vue.prototype.$http = axios

//签名
import SignCanvas from "sign-canvas";

Vue.use(SignCanvas);



import { mixin } from './utils/mixin'
Vue.mixin(mixin)

import global_ from './base.vue'

Vue.prototype.GLOBAL = global_

import moment from 'moment'
Vue.prototype.$moment = moment

import Meta from 'vue-meta';
Vue.use(Meta);  //动态添加meta


import wechatTitle from 'vue-wechat-title'
Vue.use(wechatTitle) //标题切换
// 判断是不是手机端微信或者电脑端微信浏览器
if (/(micromessenger)/i.test(navigator.userAgent)) {
  if (/(WindowsWechat)/i.test(navigator.userAgent) || /(wechatdevtools)/i.test(navigator.userAgent)) {
    //电脑微信或者开发者工具
    Vue.prototype.isWechat = false
  } else {
    //手机微信打开的浏览器
    axios.post('/index.php/wechat/isHaveAppId').then(res => {
      if (res.data.code == 0) {
        Vue.prototype.isWechat = true
      } else {
        Vue.prototype.isWechat = false
      }
    })
  }

} else {
  Vue.prototype.isWechat = false
}

new Vue({
  render: h => h(App),
  router,
  store,
  data:function(){
    return{
        backgroundColor: '1123123123'   // 定义的变量
    }
  },
}).$mount('#app')
