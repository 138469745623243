
const state = {
  tit: '132',
  
}

const mutations = {
  GET_TITLE: (state, tit) => {
    state.tit = tit
  },
 
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
