<template>
  <div id="app">
    	<router-view v-wechat-title="title" ></router-view>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/>
    <div>
      <van-button @click="yuy">语言</van-button>
    </div> -->
    
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import tts from './utils/voice'
import {setCookie} from '@/utils/env'
import "#/css/iconfont/iconfont.css"
import wx from 'weixin-js-sdk'
export default {
  name: 'App',
  // components: {
  //   HelloWorld
  // }
  // methods: {
  //   yuy() {
  //     tts.speek('')
  //   }
  // },
  data() {
    return {
     
    }
  },
  created () {
    setCookie('versions','1.3.0');
  },
  computed: {
    title() {
      let tit = this.$route.meta.title ? this.$route.meta.title : '移动端拆车件'
      return tit
    }
  },
  mounted () {
    
  },
  methods: {
      	
  
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
