import Vue from 'vue'
import Router from 'vue-router'
import { getCookie } from '../utils/env'
import store from '../store'
import bus from '@/utils/bus'
Vue.use(Router)

var title = ''

const Routes = [
    {
        path: '*',
        name: '未登录扫码界面',
        component: () => import('@/views/login'),

    },
    {
        path: '/login',
        name: '未登录扫码界面',
        component: () => import('@/views/login'),

    },
    {
        path: '/autoWeight',
        name: '自动地磅称重',
        component: () => import('@/views/autoWeight'),

    },
    {
        path: '/autoWeight/carIn',
        name: '自动地磅称重',
        component: () => import('@/views/autoWeight/carIn'),

    },
    {
        path: '/autoWeight/saleOut',
        name: '自动地磅称重',
        component: () => import('@/views/autoWeight/saleOut'),

    },
    //拆解
    {
        path: '/holle',
        name: '等待拆解',
        component: () => import('@/views/holle'),
        meta: { isAuth: true }
    },
    //拆解
    {
        path: '/WaitingDisassembly',
        name: '等待拆解',
        // component: () => import('@/views/DisassemblyDistribution/WaitingDisassembly'),
        component: () => import('@/views/moAllocation'),
        meta: { isAuth: true }
    },
    {
        path: '/AllocationPage',
        name: '拆解分配',
        // component: () => import('@/views/DisassemblyDistribution/WaitingDisassembly'),
        component: () => import('@/views/moAllocation/AllocationPage'),
        meta: { isAuth: true }
    },
    {
        path: '/DisassemblyDistribution',
        name: '拆解分配',
        component: () => import('@/views/DisassemblyDistribution/DisassemblyDistribution'),
        meta: { isAuth: true }
    },
    {
        path: '/InDistribution',
        name: '分配中',
        component: () => import('@/views/DisassemblyDistribution/InDistribution'),
        meta: { isAuth: true }
    },
    {
        path: '/receiving',
        name: '拆解接收',
        component: () => import('@/views/DisassemblyDistribution/receiving'),
        meta: { isAuth: true }
    },
    {
        path: '/OA',
        name: 'OA审核',
        component: () => import('@/views/OA/list'),
        meta: { isAuth: true }
    },

    {
        path: '/OA/detail',
        name: 'OA审核',
        component: () => import('@/views/OA/detail'),
        meta: { isAuth: true }
    },

    {
        path: '/addNew',
        name: '新增临时拆卖物',
        component: () => import('@/views/DisassemblyDistribution/addNew'),
        meta: { isAuth: true }
    },
    {
        path: '/DisassemblyRecord',
        name: '拆解记录',
        component: () => import('@/views/DisassemblyDistribution/DisassemblyRecord'),
        meta: { isAuth: true }
    },
    //文件上传
    {
        path: '/upload',
        name: '上传文件',
        component: () => import('@/views/DocumentUpload/upload'),
        meta: { isAuth: true }
    },
    {
        path: '/CertificateList',
        name: '文件列表',
        component: () => import('@/views/DocumentUpload/CertificateList'),
        meta: { isAuth: true }
    },
    {
        path: '/inspect',
        name: '检查',
        component: () => import('@/views/DocumentUpload/inspect'),
        meta: { isAuth: true }
    },
    //导向页面
    {
        path: '/guide',
        name: '导向',
        component: () => import('@/views/guide'),
        meta: { isAuth: true }
    },
    //配件详情
    {
        path: '/Detail',
        name: '导向',
        component: () => import('@/views/Detail'),

    },
    //配件详情
    {
        path: '/vehicleInfo',
        name: '导向',
        component: () => import('@/views/vehicleInfo/index.vue'),

    },
    {
        path: '/img',
        name: '导向',
        component: () => import('@/views/DisassemblyDistribution/img'),
        meta: { isAuth: true }
    },
    //拖车订单 

    {
        path: '/address',
        name: '获取地址',
        component: () => import('@/views/TrailerOrder/address'),
    },
    {
        path: '/OrderList',
        name: '订单列表',
        component: () => import('@/views/TrailerOrder/OrderList'),
        meta: { isAuth: true }
    },
    {
        path: '/OrderDetail',
        name: '订单详情',
        component: () => import('@/views/TrailerOrder/OrderDetail'),
        meta: { isAuth: true }
    },
    {
        path: '/StartTransportation',
        name: '开始运输',
        component: () => import('@/views/TrailerOrder/StartTransportation'),
        meta: { isAuth: true }
    },
    {
        path: '/returnJourney',
        name: '返程',
        component: () => import('@/views/TrailerOrder/returnJourney'),
        meta: { isAuth: true }
    },
    {
        path: '/arrive',
        name: '到达',
        component: () => import('@/views/TrailerOrder/arrive'),
        meta: { isAuth: true }
    },
    /************************************************* */
    {
        path: '/BiddingList',
        name: '竞价',
        component: () => import('@/views/Bidding/BiddingList'),
        meta: { metaInfo: { title: title } }
    },
    {
        path: '/particularsInfo',
        name: '竞价详情',
        component: () => import('@/views/Bidding/particularsInfo'),
        meta: { metaInfo: { title: title } }
    },
    {
        path: '/UrlEnding',
        name: '网址介绍',
        component: () => import('@/views/UrlEnding/index.vue'),
        meta: { metaInfo: { title: title } }
    },
    {
        path: '/QualificationApplication',
        name: '竞价资格申请',
        component: () => import('@/views/QualificationApplication/index.vue'),
        meta: { metaInfo: { title: title } }
    },
    {
        path: '/applyFor',
        name: '质申请页面',
        component: () => import('@/views/QualificationApplication/applyFor.vue'),
        meta: { metaInfo: { title: title } }
    },
    {
        path: '/ScrapApplyFor',
        name: '质申请页面',
        component: () => import('@/views/ScrapApplyFor/index.vue'),
        meta: { metaInfo: { title: title } }
    },
    {
        path: '/empty',
        name: '授权',
        component: () => import('@/views/empty/index.vue')
    },
    /*////////////////////************************************ */
    {
        path: '/bulletinBoard',
        name: '',
        component: () => import('@/views/bulletinBoard/index.vue'),
        meta: { isAuth: true }
    },
    {
        path: '/Recognition',
        name: '图片识别',
        component: () => import('@/views/PhotoRecognition/index.vue'),
        meta: { isAuth: true }
    },
    {
        path: '/Entering',
        name: '图片识别',
        component: () => import('@/views/Entering/index.vue'),
        meta: { isAuth: true }
    },
    {
        path: '/CarInfo',
        name: '车辆信息入了',
        component: () => import('@/views/CarInfo/index.vue'),
        meta: { isAuth: true }
    },
    {
        path: '/Household',
        name: '车辆信息入了',
        component: () => import('@/views/Household/index.vue'),
        meta: { isAuth: true }
    },
    {
        path: '/OwnerInfo',
        name: '车主信息录入',
        component: () => import('@/views/OwnerInfo/index.vue'),
        meta: { isAuth: true }
    },
    {
        path: '/AllInfo',
        name: '车主信息录入',
        component: () => import('@/views/AllInformationCapacity/index.vue'),
        meta: { isAuth: true }
    },
    {
        path: '/CarQuery',
        name: '车辆查询',
        component: () => import('@/views/CarQuery/index.vue'),
        meta: { isAuth: true }
    },
    {
        path: '/FullCar',
        name: '整车录入',
        component: () => import('@/views/FullCar/index.vue'),
        meta: { isAuth: true }
    },
    {
        path: '/DestroyedLine',
        name: '毁形',
        component: () => import('@/views/DestroyedLine/index.vue'),
        meta: { isAuth: true }
    },
    {
        path: '/GetDefineNum',
        name: '自定义进场编码',
        component: () => import('@/views/GetDefineNum/index.vue'),
        meta: { isAuth: true }
    },
    {
        path: '/Quality',
        name: '质检',
        component: () => import('@/views/Quality/index.vue'),
        meta: { isAuth: true }
    },
    {
        path: '/AddQuality',
        name: '质检add',
        component: () => import('@/views/Quality/AddQuality.vue'),
        meta: { isAuth: true }
    },
    {
        path: '/TransportationBatch',
        name: '运输批次',
        component: () => import('@/views/Quality/TransportationBatch.vue'),
        meta: { isAuth: true }
    },
    {
        path: '/QualityInspectionConfirmed',
        name: '运输批次',
        component: () => import('@/views/Quality/QualityInspectionConfirmed.vue'),
        meta: { isAuth: true }
    },
    {
        path: '/ConfirmPurchasePrice',
        name: '确认价格',
        component: () => import('@/views/Quality/ConfirmPurchasePrice.vue'),
        meta: { isAuth: true }
    },
    {
        path: '/MyShopping',
        name: '我的采购',
        component: () => import('@/views/Quality/MyShopping.vue'),
        meta: { isAuth: true }
    },
    {
        path: '/ClosedCarStorage',
        name: '销户入库',
        component: () => import('@/views/ClosedCarStorage/index.vue'),
        meta: { isAuth: true }
    },
    {
        path: '/AGV',
        name: 'AGV',
        component: () => import('@/views/AGV/index'),
        meta: { isAuth: true }
    },
    {
        path: '/ProcessTask',
        name: '工序任务',
        component: () => import('@/views/ProcessTask/index'),
        meta: { isAuth: true }
    },
    {
        path: '/StaffChanges',
        name: '员工更改',
        component: () => import('@/views/ProcessTask/StaffChanges'),
        meta: { isAuth: true }
    },
    {
        path: '/TaskOk',
        name: '任务完成',
        component: () => import('@/views/ProcessTask/TaskOk'),
        meta: { isAuth: true }
    },
    {
        path: '/DispatchPage',//给ERP
        name: '厂内调度',
        component: () => import('@/views/ProcessTask/DispatchPage.vue'),
    },
    {
        path: '/AdvStorage',
        name: '库位',
        component: () => import('@/views/AGV/AdvStorage'),
        meta: { isAuth: true }
    },
    {
        path: '/Agvlist',
        name: '列表',
        component: () => import('@/views/AGV/Agvlist'),
        meta: { isAuth: true }
    },
    {
        path: '/wechatAuthorization',//给ERP
        name: '授权',
        component: () => import('@/views/wechatAuthorization/index.vue'),
    },
    {
        path: '/SchedulingTheHall',//给ERP
        name: '授权',
        component: () => import('@/views/SchedulingTheHall/index.vue'),
    },
    //保全管理
    {
        path: '/SecurityManagement',
        name: '派车任务',
        component: () => import('@/views/SecurityManagement/index'),
        meta: { isAuth: true }
    },
    {
        path: '/InfoGather',
        name: '派车任务',
        component: () => import('@/views/InfoGather/index'),
        meta: { isAuth: true }
    },
    {
        path: '/PreserveTheSend',
        name: '车辆信息采集',
        component: () => import('@/views/PreserveTheSend/index'),
        meta: { isAuth: true }
    },
    {
        path: '/BqList',
        name: '车辆列表',
        component: () => import('@/views/BqList/index'),
        meta: { isAuth: true }
    },
    {
        path: '/InTheAudit',
        name: '审核列表',
        component: () => import('@/views/BqList/InTheAudit'),
        meta: { isAuth: true }
    },
    {
        path: '/CarOut',
        name: '车辆离场',
        component: () => import('@/views/CarOut/index'),
        meta: { isAuth: true }
    },
    {
        path: '/Abnormal',
        name: '车辆离场照片校验',
        component: () => import('@/views/CarOut/Abnormal'),
    },
    {
        path: '/InfoImg',
        name: '车辆离场信息',
        component: () => import('@/views/CarOut/InfoImg'),
    },
    {
        path: '/outImage',
        name: '提车信息',
        component: () => import('@/views/CarOut/outImage'),
    },

    // {
    //     path: '/Registration',
    //     name: '图片识别',
    //     component: () => import('@/views/Registration/index.vue'),
    //     meta:{isAuth:true}
    // },

]



const createRouter = () => new Router({
    // mode: 'history',
    routes: Routes
})

const router = createRouter()
//路由拦截

router.beforeEach(function (to, from, next) {
    if (to.meta.isAuth) {
        if (getCookie('token')) {
            next()
        } else {
            next('/')
        }
    } else {
        next();
    }
    //   if(!getCookie('token')){
    //       if(to.path != '/'){
    //           next('/')
    //       }
    //   };

})

//添加以下代码
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}

export default router