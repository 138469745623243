export const setCookie = (name, value, Days) => {
    if (Days == null || Days == '') {
        Days = 300
    }
    var exp = new Date()
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000)
    document.cookie = name + '=' + escape(value) + '; path=/;expires=' + exp.toGMTString()
}

export const getCookie = (name) => {
    var arr,
        reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
    arr = document.cookie.match(reg)
    if (arr) {
        return unescape(arr[2])
    } else {
        return null
    }
}
export const deleteCookie = (name) => {
    setCookie(name, '', -1)
} 